/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import { AppContext } from "~context/AppContext";

import AsSeenIn from "~components/AsSeenIn";
import Banner from "~components/Banner";
import Button from "~components/Button";
import Compliments from "~components/Compliments";
import FormFooter from "~components/FormFooter";
import Issues from "~components/Issues";
import Layout from "~components/Layout";
import ProductsGrid from "~components/ProductsGrid";
import SEO from "~components/SEO";

class IndexPageComponent extends Component {
  mounted = false;

  lipOils = [];

  lipOilRefills = [];

  makeup = [];

  stuff = [];

  bestSellers = [];

  componentDidMount() {
    const { appContext } = this.props;
    this.mounted = true;

    appContext.setMenuActive(false);
    appContext.setNavText(this.props.data.markdownRemark.frontmatter.navText);

    const { data } = this.props;

    data.allShopifyProduct.edges.forEach(({ node }) => {
      if (
        !node.images.length ||
        node.handle === `sticky-pack` ||
        node.handle.includes(`beanie`)
      ) {
        return;
      }

      data.allShopifyAdminProduct.edges.forEach(allShopifyAdminProduct => {
        if (allShopifyAdminProduct.node.products) {
          allShopifyAdminProduct.node.products.forEach(shopifyAdminProduct => {
            if (shopifyAdminProduct.handle === node.handle) {
              node.admin_variant_id =
                shopifyAdminProduct.variants[0].alternative_id;
            }
          });
        }
      });

      data.allMarkdownRemark.edges.forEach(edge => {
        const { frontmatter } = edge.node;

        if (frontmatter.handle === node.handle) {
          node.fm_title = frontmatter.title;
          node.discount = frontmatter.discount;
          node.preorder = frontmatter.preorder;
          node.soldOut = frontmatter.soldOut;
          node.featuredImage = frontmatter.featuredImage;
        }
      });

      if (
        node.handle === `bronzing-duet` ||
        node.handle === `face-oil` ||
        node.handle === `the-foundation`
      ) {
        this.bestSellers.push(node);
      }
    });

    this.bestSellers.sort((a, b) => (a.title > b.title ? 1 : -1));
  }

  //

  addToCart = shopifyProduct => {
    const { cart } = this.props.appContext;

    if (cart && cart.length) {
      let added = false;

      cart.forEach(cartItem => {
        if (added) {
          return;
        }

        if (cartItem.handle === shopifyProduct.handle) {
          added = true;
          cartItem.quantity += 1;
        }
      });

      if (!added) {
        shopifyProduct.quantity = 1;
        cart.push(shopifyProduct);
      }
    } else {
      shopifyProduct.quantity = 1;
      cart.push(shopifyProduct);
    }

    this.props.appContext.setCart(cart);
    this.props.appContext.setCartActive(true);
  };

  //

  render() {
    const { frontmatter } = this.props.data.markdownRemark;
    return (
      <>
        <SEO pageTitle="Home" pathName="/" />

        <Layout className="index-page w-full relative gpu bg-sun-yellow">
          <section className="index-page__banner relative w-full mt-16 px-8 xs:px-2 flex flex-col flex-no-wrap justify-around items-start">
            <div className="flex flex-row w-full items-center">
              <h1
                className="animation-appear sm:w-full sm:mr-4 w-3/5 d1 font-medium text-black z-10"
                style={{ animationDelay: `100ms` }}
              >
                {frontmatter.bannerMedia.bannerText}
              </h1>

              <div
                className="animation-appear index-page__banner--media absolute right-0 -mt-12 xs:pb-20 xs:-mr-8"
                style={{ animationDelay: `200ms` }}
              >
                <Img
                  fluid={frontmatter.bannerMedia.src.childImageSharp.fluid}
                />
              </div>
            </div>

            <Button
              linkTo="/products"
              className="animation-appear button--transparent f3 font-medium text-black mt-16 text-left"
              text="Shop Casual Cosmetics™"
              style={{ animationDelay: `300ms` }}
            />
          </section>

          <ProductsGrid
            bgColor="bg-sun-yellow"
            heading="Best Sellers."
            products={this.bestSellers}
            oneClick
            button={{
              linkTo: `/products`,
              className: `button--transparent f3 font-medium text-black`,
              text: `Shop Now`
            }}
          />

          {/* {frontmatter.compliments && (
            <Compliments compliments={frontmatter.compliments} />
          )} */}

          {frontmatter.asSeenIn && <AsSeenIn asSeenIn={frontmatter.asSeenIn} />}

          {frontmatter.makeunderBannerMedia &&
            frontmatter.makeunderBannerMedia.src &&
            frontmatter.makeunderBannerMedia.bannerText && (
              <>
                <Banner
                  bannerImage={
                    frontmatter.makeunderBannerMedia.src.childImageSharp.fluid
                  }
                  bannerText={frontmatter.makeunderBannerMedia.bannerText}
                  button={{
                    buttonBg: `bg-pale-red`,
                    buttonTextColor: `text-dark-navy-blue`,
                    buttonText: `Book Now`,
                    buttonLink: `/makeunder`
                  }}
                />
              </>
            )}

          {/* TODO - pull these from wordpress */}
          <Issues
            issues={[
              {
                image: frontmatter.issues[0],
                title: `We are a dream`,
                excerpt: `I dreamt of you last night.`,
                link: `https://issues.itsallfluff.com/we-are-a-dream/`
              },
              {
                image: frontmatter.issues[1],
                title: `Anonymous`,
                excerpt: `there is a life dwelling inside that no longer exists.`,
                link: `https://issues.itsallfluff.com/anonymous/`
              },
              {
                image: frontmatter.issues[2],
                title: `Real Life Dreams`,
                excerpt: `I am a cosmic child of the universe, comprised of stardust and dreams.`,
                link: `https://issues.itsallfluff.com/real-life-dreams/`
              }
            ]}
          />
          <FormFooter headlineText="Headline. Goes here." />
        </Layout>
      </>
    );
  }
}

const IndexPage = props => {
  return (
    <AppContext.Consumer>
      {appContext => <IndexPageComponent appContext={appContext} {...props} />}
    </AppContext.Consumer>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1080) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              src
            }
          }
        }
        navText
        bannerMedia {
          bannerText
          src {
            childImageSharp {
              fluid(maxWidth: 1080) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                src
              }
            }
          }
        }
        compliments {
          src {
            childImageSharp {
              fluid(maxWidth: 480) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        makeunderBannerMedia {
          bannerText
          src {
            childImageSharp {
              fluid(maxWidth: 1080) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                src
              }
            }
          }
        }
        issues {
          src {
            childImageSharp {
              fluid(maxWidth: 480) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        asSeenIn {
          src {
            childImageSharp {
              fluid(maxWidth: 480) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            handle
            discount
            preorder
            soldOut
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1080) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  src
                }
              }
            }
          }
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          productType
          vendor
          variants {
            id
            title
            image {
              originalSrc
            }
            price
          }
        }
      }
    }
    allShopifyAdminProduct {
      edges {
        node {
          products {
            handle
            variants {
              alternative_id
            }
          }
        }
      }
    }
  }
`;
