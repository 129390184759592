/* eslint-disable react/prop-types */

import React, { Component } from "react";

import { AppContext } from "~context/AppContext";
import WordpressProvider, { WordpressContext } from "~context/WordpressContext";

import Button from "~components/Button";

class IssuesComponent extends Component {
  componentDidMount() {
    const { wordpressContext } = this.props;

    if (wordpressContext.featuredWordpressPosts.length === 0) {
      wordpressContext.getFeaturedPosts().then(posts => {
        wordpressContext.setFeaturedPosts(posts.data);
      });
    }
  }

  render() {
    const { wordpressContext } = this.props;

    return (
      <section className="issues relative pt-16 pb-32 xs:pb-12 px-6 bg-light-grey-blue">
        <h3 className="f2 text-black font-medium">We all have issues.</h3>

        <Button
          linkTo="/issues"
          className="button--transparent f3 font-medium text-black pt-4"
          text="Read Ours"
        />

        <div className="w-full flex items-center justify-center xs:block mt-16 xs:mt-8">
          {wordpressContext.featuredWordpressPosts.length > 0 &&
            wordpressContext.featuredWordpressPosts.map(
              (featuredPost, index) => {
                const featuredPostKey = `featuredPost-${index}`;

                return (
                  <div
                    key={featuredPostKey}
                    className="issues__item w-1/4 xs:w-full relative block mx-6 xs:my-4 xs:mx-0"
                  >
                    <a
                      className="block"
                      href={featuredPost.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="issues__image w-full h-full object-cover"
                        src={
                          featuredPost._embedded[`wp:featuredmedia`][0]
                            .source_url
                        }
                        alt="Issue"
                      />
                      <h4
                        className="f4 text-black font-medium pt-12 xs:pt-4"
                        dangerouslySetInnerHTML={{
                          __html: featuredPost.title.rendered
                        }}
                      ></h4>
                      {/* <h4 className="f4 text-black font-light pt-4 xs:py-2">
                        {issue.excerpt}
                      </h4> */}
                    </a>

                    {this.props.appContext.device !== `mobile` && (
                      <Button
                        linkTo={featuredPost.link}
                        className="button--transparent b2 font-medium text-black pt-4"
                        text="Read"
                      />
                    )}
                  </div>
                );
              }
            )}
        </div>
      </section>
    );
  }
}

const Issues = props => {
  const { issues } = props;

  return (
    <WordpressProvider>
      <WordpressContext.Consumer>
        {wordpressContext => (
          <AppContext.Consumer>
            {appContext => (
              <IssuesComponent
                appContext={appContext}
                issues={issues}
                wordpressContext={wordpressContext}
              />
            )}
          </AppContext.Consumer>
        )}
      </WordpressContext.Consumer>
    </WordpressProvider>
  );
};

export default Issues;
