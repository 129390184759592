/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

import { AppContext } from "~context/AppContext";
import Button from "~components/Button";

class ProductsGridComponent extends Component {
  addToCart = shopifyProduct => {
    const { cart } = this.props.appContext;

    const selectedProduct = {
      handle: shopifyProduct.handle,
      image: shopifyProduct.variants[0].image.originalSrc,
      name: shopifyProduct.title,
      price: shopifyProduct.variants[0].price,
      quantity: 1,
      variantId: shopifyProduct.admin_variant_id
    };

    if (cart && cart.length) {
      let added = false;

      cart.forEach(cartItem => {
        if (added) {
          return;
        }

        if (cartItem.variantId === selectedProduct.variantId) {
          added = true;
          cartItem.quantity += 1;
        }
      });

      if (!added) {
        selectedProduct.quantity = 1;
        cart.push(selectedProduct);
      }
    } else {
      selectedProduct.quantity = 1;
      cart.push(selectedProduct);
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: `Add Product`,
      price: selectedProduct.name
    });

    this.props.appContext.setCart(cart);
    this.props.appContext.setCartActive(true);
  };

  //

  render() {
    const {
      bgColor,
      oneClick,
      heading,
      products,
      button,
      withLink
    } = this.props;

    return (
      <section className={`products-grid w-screen relative ${bgColor}`}>
        <div
          className="products-grid__scroll animation-appear w-full relative pt-12"
          style={{ animationDelay: `200ms` }}
        >
          {heading && (
            <h2 className="products-grid__scroll__heading py-8 xs:py-4 px-6 xs:px-0 f2 text-black font-medium">
              {heading}
            </h2>
          )}

          {button && (
            <Button
              linkTo={button.linkTo}
              className={`${button.className} px-6 xs:px-0`}
              text={button.text}
            />
          )}

          <ul className="products-grid__scroll__flex w-full relative flex flex-row flex-wrap items-stretch justify-between">
            {products.map(product => (
              <li
                key={product.handle}
                className="products-grid__product w-1/3 relative flex flex-col justify-between mb-24 px-6"
              >
                {/* {product.soldOut && (
                  <div className="products-grid__scroll__sold-out w-full h-full absolute top-0 right-0 z-30 flex items-center justify-center">
                    <span className="px-16 py-2 f3 bg-navy text-white">
                      SOLD OUT
                    </span>
                  </div>
                )} */}

                {product.discount > 0 &&
                  (product.handle === `bronzing-duet` ||
                    product.handle === `the-foundation`) && (
                    <div className="products-grid__product__discount absolute top-0 right-0 z-10 px-12 py-3 bg-periwinkle b2 font-medium uppercase text-white">
                      SAVE ${product.discount}
                    </div>
                  )}

                <Link to={`/products/${product.handle}`}>
                  <div className="products-grid__product__image w-full relative overflow-hidden">
                    {product.featuredImage && (
                      <Img
                        className="products-grid__product__image--gatsby"
                        fluid={product.featuredImage.childImageSharp.fluid}
                      />
                    )}
                  </div>

                  <h3 className="my-2 f4 font-medium text-black">
                    {(product.fm_title && product.fm_title) || product.title}
                  </h3>
                </Link>

                {!product.soldOut ? (
                  <>
                    {(oneClick && (
                      <button
                        type="button"
                        className={`products-grid__button w-full relative block mt-3 py-3 f4 text-sun-yellow hover:text-black font-bold bg-black ${
                          bgColor === `bg-sun-yellow`
                            ? `hover:bg-white`
                            : `hover:bg-sun-yellow`
                        }`}
                        onClick={() => this.addToCart(product)}
                      >
                        {product.preorder
                          ? `Pre-order: $${product.variants[0].price}`
                          : `Buy Now: $${product.variants[0].price}`}
                      </button>
                    )) || (
                      <Link to={`/products/${product.handle}`}>
                        <button
                          type="button"
                          className={`products-grid__button w-full relative block mt-3 py-3 f4 text-sun-yellow hover:text-black font-bold bg-black ${
                            bgColor === `bg-sun-yellow`
                              ? `hover:bg-white`
                              : `hover:bg-sun-yellow`
                          }`}
                        >
                          {(product.handle !== `lip-oil-1-clear-refill` &&
                            product.handle !== `lip-oil-2-clear-refill` &&
                            `Buy Now: $${product.variants[0].price}`) ||
                            `Pre Order`}
                        </button>
                      </Link>
                    )}
                  </>
                ) : (
                  <Link to={`/products/${product.handle}`}>
                    <button
                      type="button"
                      className={`products-grid__button w-full relative block mt-3 py-3 f4 text-sun-yellow hover:text-black font-bold bg-black ${
                        bgColor === `bg-sun-yellow`
                          ? `hover:bg-white`
                          : `hover:bg-sun-yellow`
                      }`}
                    >
                      Sold Out
                    </button>
                  </Link>
                )}
                <h4
                  className="mt-3 b3 font-light text-black"
                  style={{
                    fontSize: `12px`
                  }}
                >
                  {product.preorder ? `*Ships Jan 31st` : <br />}
                </h4>
              </li>
            ))}
          </ul>
        </div>

        {withLink && (
          <div className="w-full relative flex items-center justify-center pb-48">
            <Link to="/products" className="shopify-product__all block">
              <button
                type="button"
                className="py-3 px-6 bg-white b2 uppercase text-black"
              >
                Shop All
              </button>
            </Link>
          </div>
        )}
      </section>
    );
  }
}

const ProductsGrid = props => {
  const { bgColor, oneClick, heading, products, button, withLink } = props;

  return (
    <AppContext.Consumer>
      {appContext => (
        <ProductsGridComponent
          appContext={appContext}
          bgColor={bgColor}
          oneClick={oneClick}
          heading={heading}
          products={products}
          button={button}
          withLink={withLink}
        />
      )}
    </AppContext.Consumer>
  );
};

export default ProductsGrid;
