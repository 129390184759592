/* eslint-disable react/prop-types */

import React, { Component } from "react";
import Img from "gatsby-image";

import { AppContext } from "~context/AppContext";
import Button from "~components/Button";

class ComplimentsComponent extends Component {
  componentDidMount() {}

  //

  render() {
    const { compliments } = this.props;

    return (
      <section className="compliments relative pt-4 pb-32 xs:pb-16 px-6 xs:px-2 bg-sun-yellow">
        <h3 className="f2 text-black font-medium py-8 xs:py-4">Compliments</h3>

        <Button
          linkTo="https://www.instagram.com/itsall.fluff/"
          className="button--transparent f3 font-medium text-black"
          text="See Instagram"
        />

        <div className="w-full flex items-center justify-center xs:block mt-16">
          {compliments.map((image, index) => {
            const complimentKey = `compliment-${index}`;
            return (
              <div
                key={complimentKey}
                className="w-1/4 xs:w-full relative block px-6 xs:my-16"
              >
                <Img
                  fluid={image.src.childImageSharp.fluid}
                  alt={`Compliment ${index}`}
                />
              </div>
            );
          })}
        </div>
      </section>
    );
  }
}

const Compliments = props => {
  const { compliments } = props;

  return (
    <AppContext.Consumer>
      {appContext => (
        <ComplimentsComponent
          appContext={appContext}
          compliments={compliments}
        />
      )}
    </AppContext.Consumer>
  );
};

export default Compliments;
