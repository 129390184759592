/* eslint-disable react/prop-types */

import React, { Component } from "react";
import Img from "gatsby-image";

import { AppContext } from "~context/AppContext";

class AsSeenInComponent extends Component {
  componentDidMount() {}

  //

  render() {
    const { asSeenIn } = this.props;

    return (
      <section className="as-seen-in relative pt-4 pb-32 px-6 xs:px-2 bg-sun-yellow">
        <h3 className="f2 text-black font-medium py-8 xs:py-4">As seen in:</h3>

        <div className="flex flex-row flex-wrap items-center justify-center">
          {asSeenIn.map((logo, index) => {
            const asSeenInKey = `asSeenIn-${index}`;
            return (
              <div
                key={asSeenInKey}
                className="w-1/4 sm:w-1/2 flex justify-center relative block px-6 xs:px-0 my-4"
              >
                <Img
                  className="as-seen-in__logo w-full"
                  fluid={logo.src.childImageSharp.fluid}
                  alt={`asSeenIn ${index}`}
                />
              </div>
            );
          })}
        </div>
      </section>
    );
  }
}

const AsSeenIn = props => {
  const { asSeenIn } = props;

  return (
    <AppContext.Consumer>
      {appContext => (
        <AsSeenInComponent appContext={appContext} asSeenIn={asSeenIn} />
      )}
    </AppContext.Consumer>
  );
};

export default AsSeenIn;
