/* eslint-disable react/prop-types */

import React from "react";
import { Link } from "gatsby";

const Button = props => {
  return (
    <>
      {props.linkTo.startsWith(`http`) ? (
        <a href={props.linkTo} target="_blank" rel="noopener noreferrer">
          <button type="button" className={`${props.className}`}>
            {props.text}&nbsp;
            <span className="button--arrow">➺</span>
          </button>
        </a>
      ) : (
        <Link to={props.linkTo}>
          <button type="button" className={`${props.className}`}>
            {props.text}&nbsp;
            <span className="button--arrow">➺</span>
          </button>
        </Link>
      )}
    </>
  );
};

export default Button;
